import BlazeSlide from "components/Slider/BlazeSlider/BlazeSlide/BlazeSlide";
import BlazeVideoSlide from "components/Slider/BlazeSlider/BlazeSlide/BlazeVideoSlide/BlazeVideoSlide.jsx";
import { truncateText } from "utils/text";
import { fetchYouTubeData } from "./video";

export function processSlide(slide) {
  const processedSlide = {
    ...slide,
    sliderImageLarge:
      slide.sliderImageLarge?.data?.attributes ?? slide.sliderImageLarge,
  };

  return processedSlide;
}

export function generateBlazeSlides({ slides, type, ctaButton, props }) {
  const generatedSlides = slides?.map((slideObject, index) => {
    if (!!slideObject?.video || !!slideObject?.video?.url) {
      const videoSlide = generateBlazeVideoSlide(
        slideObject?.video,
        slideObject?.slug,
        slideObject?.title
      );

      return videoSlide;
    }

    const slide = processSlide(slideObject);

    const id = slide.id ?? null;

    const { mobile: mobileImageURL, desktop: desktopImageURL } =
      getSliderImageURL(slide);

    let text = slide.sliderDescription;
    let mobileText = "";
    let link = slide.slug;
    let favoriteType = "";
    const slideWidth = slide?.sliderImageLarge?.width ?? null;
    const slideHeight = slide?.sliderImageLarge?.height ?? null;

    if (type === "blog" || type === "blogMinimal") {
      const { slideLink, slideText, slideMobileText } = generateBlogSlide(
        slide,
        link,
        type
      );
      link = slideLink;
      text = slideText;
      mobileText = slideMobileText;
    }

    if (type === "experience" || type === "experienceMinimal") {
      const {
        slideLink,
        slideMobileText,
        favoriteType: slideFavoriteType,
      } = generateExperienceSlide(slide, link, text, mobileText, type);

      favoriteType = slideFavoriteType;
      link = slideLink;
      mobileText = slideMobileText;
    }

    if (type === "destination") {
      const { slideLink, slideMobileText } = generateDestinationSlide(
        slide,
        link
      );

      link = slideLink;
      mobileText = slideMobileText;
    }

    return (
      <BlazeSlide
        key={`${desktopImageURL}-${id}`}
        id={id}
        type={type}
        imageURL={desktopImageURL}
        imageWidth={slideWidth ?? null}
        imageHeight={slideHeight ?? null}
        link={link ?? null}
        alt={slide?.sliderImageAlt}
        title={slide?.sliderHeader || slide?.title}
        text={text ?? null}
        mobileText={mobileText ?? null}
        ctaButton={ctaButton}
        index={index}
        loading={index === 0 ? "eager" : "lazy"}
        favoriteType={favoriteType}
        {...props}
      />
    );
  });

  return generatedSlides.filter(
    (slide) => (slide && !!slide?.props?.imageURL) || !!slide?.props?.videoId
  );
}

function generateBlogSlide(slide, link, type) {
  const slideLink = link ? `/stories/${link}` : null;

  const slideText = (
    <p>
      {slide?.publishedAt} | {slide?.author?.authorName}
    </p>
  );

  const descriptionText = slide?.sliderDescription ?? slide?.description;
  const description =
    descriptionText?.length != null ? truncateText(descriptionText, 110) : "";

  const slideMobileText =
    type !== "blogMinimal" ? (
      <div className="wn-slide-text">
        <p className="wn-slide-title">{slide?.title}</p>

        <p className="wn-slide-subtitle">
          <span>{slide?.author?.authorName}</span>
          <br />
          <span>{slide?.date_created}</span>
        </p>
      </div>
    ) : (
      <div className="wn-slide-text">
        <p className="wn-slide-title">{slide?.title}</p>

        <p className="wn-slide-subtitle">
          <span>{description && description}</span>
        </p>
      </div>
    );

  return { slideLink, slideText, slideMobileText };
}

function generateExperienceSlide(slide, link, text, mobileText, type) {
  let slideLink = link;
  let slideText = text;
  let slideMobileText = mobileText;

  const favoriteType = ["experience", "experienceMinimal"].includes(type)
    ? "experiences"
    : "";

  if (link && !link.includes("/experience/")) {
    slideLink = link ? `/experience/${link}` : null;
  }

  if (type === "experienceMinimal") {
    slideText = truncateText(slide.sliderDescription, 110);
    slideMobileText = (
      <div className="wn-slide-text">
        <p>{slideText}</p>
      </div>
    );
  } else {
    slideMobileText = (
      <div className="wn-slide-text">
        <p className="wn-slide-title">
          {slide.sliderHeader || slide.sliderTitle}
        </p>
        <p className="wn-slide-subtitle">
          <span>
            {slide?.experienceData?.country && slide?.experienceData?.country} -{" "}
          </span>
          <span>
            from $
            {slide?.experienceData?.currencies &&
              slide?.experienceData?.currencies.usd}
            /week
          </span>
        </p>
      </div>
    );
  }

  return { slideLink, slideMobileText, slideText, favoriteType };
}

function generateDestinationSlide(slide, link) {
  let slideLink = link ? `/destinations/${link}` : null;
  let slideMobileText = "";

  const description = truncateText(slide.sliderDescription, 110);

  slideMobileText = (
    <div className="wn-slide-text">
      <p className="wn-slide-title">
        {slide.sliderHeader || slide.sliderTitle}
      </p>
      <p className="wn-slide-subtitle">
        <span>{slide?.sliderDescription && description}</span>
      </p>
    </div>
  );

  return { slideLink, slideMobileText };
}

export function getSliderImageURL(slide) {
  const firstImageIndex =
    slide?.HeroSlider?.findIndex((slide) => !!slide?.sliderImageLarge?.url) ??
    0;

  const mobileImageUrl =
    slide?.sliderImageSmall?.url ||
    slide?.sliderImageSmall?.formats?.small?.url ||
    slide?.sliderImageSmall?.formats?.large?.url ||
    slide?.HeroSlider?.[firstImageIndex]?.sliderImageSmall?.url ||
    slide?.HeroSlider?.[firstImageIndex]?.sliderImageSmall?.formats?.small
      ?.url ||
    slide?.HeroSlider?.[firstImageIndex]?.sliderImageSmall?.formats?.thumbnail
      ?.url ||
    slide?.sliderImage?.url;

  const desktopImageURL =
    slide?.sliderImageLarge?.url ||
    slide?.sliderImageLarge?.formats?.large?.url ||
    slide?.HeroSlider?.[firstImageIndex]?.sliderImageLarge?.url ||
    slide?.HeroSlider?.[firstImageIndex]?.sliderImageSmall?.formats?.large
      ?.url ||
    slide?.HeroSlider?.[firstImageIndex]?.sliderImageLarge?.formats?.large
      ?.url ||
    slide?.sliderImage?.url;

  return {
    mobile: mobileImageUrl,
    desktop: desktopImageURL,
  };
}

function generateBlazeVideoSlide(videoObject, pageLink, linkText) {
  const video = videoObject;

  if (!video?.url || video?.url == undefined) {
    return null;
  }

  let title, thumbnail, largeThumbnail, description, smallThumbnail;

  if (video?.youTubeData) {
    const { snippet } = video?.youTubeData;

    title = snippet.title;
    description = snippet?.description ?? null;
    smallThumbnail = snippet.thumbnails?.default?.url;
    thumbnail =
      snippet.thumbnails?.medium?.url || snippet.thumbnails?.default?.url;
    largeThumbnail =
      snippet.thumbnails?.maxres?.url ||
      snippet.thumbnails?.standard?.url ||
      snippet.thumbnails?.high?.url;
  }

  const videoSlide = (
    <BlazeVideoSlide
      videoId={video.url}
      title={title ?? video?.title}
      metadata={video?.youTubeData}
      thumbnail={thumbnail ?? null}
      imageURL={largeThumbnail ?? null}
      link={pageLink ?? null}
      linkText={linkText}
      // playing={currentVideo?.videoId === video.url ? true : false}
      key={video.url}
    />
  );

  return videoSlide;
}

export function generateBlazeVideoSlides(videos, pageLink, linkText) {
  const slides = [];

  for (const video of videos) {
    const slide = generateBlazeVideoSlide(video, pageLink, linkText);
    slides.push(slide);
  }

  return slides;
}
