export const truncateText = (text, length) => {
  if (!text) {
    return;
  }
  if (text.length > length) {
    return text.substring(0, text.lastIndexOf(" ", length)) + "...";
  }
  return text;
};

export const convertWordsToCapitalCase = (text) => {
  if (!text) {
    return;
  }
  return text.replace(/\w\S*/g, (w) =>
    w.replace(/^\w/, (c) => c.toUpperCase())
  );
};

export function insertParagraphBreaks(text) {
  const wordCount = text.match(/\S+/g)?.length;

  // If word count is less than 60, return the original text
  if (wordCount < 40) {
    return text;
  }

  // Check if breaks are needed
  const currentParagraphs = text.split("\n");
  const breakCount = currentParagraphs?.length;

  // There should be at least one break per 50 words
  const desiredBreakCount = Math.round(wordCount / 45);

  if (breakCount >= desiredBreakCount) {
    return text;
  }

  // Define a regular expression to match sentences
  const sentenceRegex = /[^.!?]*[.!?]/g;

  // Split the text into an array of sentences
  const sentences = text.match(sentenceRegex);

  // If there are no sentences or the input is empty, return the original text
  if (!sentences || sentences.length === 0) {
    return text;
  }

  // Determine the desired number of sentences per paragraph (2-4 sentences)
  const sentencesPerParagraph = Math.floor(Math.random() * 3) + 2;

  // Create an array to store paragraphs
  const paragraphs = [];

  // Iterate over the sentences and group them into paragraphs
  for (let i = 0; i < sentences.length; i += sentencesPerParagraph) {
    const paragraph = sentences.slice(i, i + sentencesPerParagraph).join(" ");
    paragraphs.push(paragraph);
  }

  // Join the paragraphs with "\n" to create the final result
  const result = paragraphs.join("\n\n");

  return result;
}
