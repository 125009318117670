// A function to insert a string of comma-separated cloudinary tags into a Cloudinary URl
export function insertTagsInCloudinaryUrl(url, tags) {
  // Split the URL at the "upload/" part
  const parts = url.split("upload/");

  // Check if the URL has the "upload/" part
  if (parts.length !== 2) {
    throw new Error("Invalid Cloudinary URL");
  }

  // Insert the tags after the "upload/" part
  const modifiedUrl = parts[0] + "upload/" + tags + "/" + parts[1];

  return modifiedUrl;
}

// A function to convert width/height to a new aspect ratio
export function convertAspectRatio(width, height, newAspectRatio) {
  // Calculate the current aspect ratio
  const currentAspectRatio = width / height;

  // If the current aspect ratio is equal to the new aspect ratio, return the original width and height
  if (currentAspectRatio === newAspectRatio) {
    return { width, height };
  }

  let adjustedWidth, adjustedHeight;

  if (currentAspectRatio > newAspectRatio) {
    // The current aspect ratio is wider than the new aspect ratio, so the width needs to be scaled down
    adjustedWidth = width * (newAspectRatio / currentAspectRatio);
    adjustedHeight = height;
  } else {
    // The current aspect ratio is taller than the new aspect ratio, so the height needs to be scaled down
    adjustedWidth = width;
    adjustedHeight = height * (currentAspectRatio / newAspectRatio);
  }

  return {
    width: Math.floor(adjustedWidth),
    height: Math.floor(adjustedHeight),
  };
}

export function convertStrapiAspectRatio(strapiAspectRatio) {
  return strapiAspectRatio.replace("w_h_", "").split("_").join("/");
}

// reduce image size and quality usin cloudinary

export function reduceSize(imageUrl) {
  //https://res.cloudinary.com/dail4fbms/image/upload/v1637215815/damiano_baschiera_h_FXZ_5c_Nfk_Ok_unsplash_b4fce0aa60.jpg
  return insertCloudinaryTags(imageUrl, /*"w_600,*/ "q_auto,f_auto,f_webp");
}

export function makeThumbnailViaCloudinary(imageUrl) {
  return insertCloudinaryTags(
    imageUrl,
    /*"c_thumb,*/ "f_webp,q_auto,w_374,h_220" /*",w_374"*/
  );
}

export function insertCloudinaryTags(imageUrl = "", tagString) {
  const parts = imageUrl.split(",");
  const pivot = parts[parts.length - 2];
  const imagePath = `${tagString}/${pivot}`;
  parts[parts.length - 2] = imagePath;
  return parts.join("/");
}

export function nextGenImageFormat(imageUrl) {
  return insertCloudinaryTags(imageUrl, "f_webp");
}

export function sizeImage(imageUrl) {
  return insertCloudinaryTags(imageUrl, "c_fill,h_600,w_636,f_webp");
}
