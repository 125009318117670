import React, { useState } from "react";
import Image from "next/image";
import dynamic from "next/dynamic";
const ReactPlayer = dynamic(() => import("react-player/youtube"), {
  ssr: false,
});
import { useInView } from "react-cool-inview";
import { getYouTubeId } from "utils/video.js";
import Button from "components/ui/buttons/Button/Button";

// import ReactPlayer from "react-player/youtube";

import styles from "./BlazeVideoSlide.module.scss";

function BlazeVideoSlide({
  videoId,
  title,
  metadata,
  description,
  thumbnail,
  imageURL,
  link,
  linkText,
  playing = false,
  onEnded = () => null,
  playerRef,
}) {
  const [loaded, setLoaded] = useState(false);
  const [activated, setActivated] = useState(false);
  const [isPlaying, setIsPlaying] = useState(playing);
  const [isHovered, setIsHovered] = useState(false);

  // const reactPlayerRef = useRef();
  const youtubeId = getYouTubeId(videoId);

  const { observe, inView } = useInView({
    // unobserveOnEnter: true,
    onEnter: (scrollDirection, entry) => {
      setIsPlaying(true);
      // if (loaded) {
      //   setIsPlaying(true);
      //   return;
      // }
      setLoaded(true);
    },
    onLeave: (scrollDirection, entry) => {
      setIsPlaying(false);
    },
  });

  const handleHover = (event) => {
    if (event.type === "mouseenter") {
      setIsHovered(true);
    }
    if (event.type === "mouseleave") {
      setIsHovered(false);
    }

    if (activated) {
      return;
    }

    if (isPlaying === false) {
      setIsPlaying(true);
    }
    setActivated(true);
  };

  return (
    <div
      className={`${styles.container} blaze-video-slide`}
      ref={observe}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      {!activated && (
        <div
          className={`${styles.detectHover} ${
            isPlaying ? styles.activated : ""
          }}`}
          onMouseOver={handleHover}
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
        ></div>
      )}
      <div
        className={`${styles.videoContainer} ${styles.desktopVideoContainer}`}
      >
        {inView && (
          <ReactPlayer
            url={`https://www.youtube.com/watch?v=${youtubeId}`}
            volume={0}
            muted={true}
            playing={isPlaying}
            width="100%"
            height="100%"
            className={`wn-react-player`}
            onEnded={onEnded}
            controls={true}
            // light={isPlaying ? false : imageURL || true}
            light={isPlaying ? false : thumbnail}
            // light={false}
            loop={true}
            pip={true}
            ref={playerRef}
            config={{
              youtube: {
                playerVars: { modestbranding: 1 },
              },
            }}
          />
        )}
        {link && (
          <div
            className={`${styles.pageLinkContainer} ${
              isHovered ? "" : styles.hide
            }`}
          >
            <span className={`${styles.buttonWrapper}`}>
              <Button link={link}>
                <span className={`${styles.logoWrapper}`}>
                  <Image
                    src="/assets/img/logoSmallWhite.svg"
                    alt="WhereNext logo"
                    width="20"
                    height="20"
                  />
                </span>
                {linkText ? linkText : "Learn more"}
              </Button>
            </span>
          </div>
        )}
      </div>
      {/* <div className={`${styles.imageContainer} ${styles.mobileImage}`}>
        
      </div> */}
      <div className={`${styles.mobileText}`}>
        {title && <div className={`${styles.title}`}>{title}</div>}
        {metadata && (
          <div className={`${styles.metadata}`}>
            <span>{metadata?.snippet?.channelTitle}</span>
            <span>
              {" "}
              - {new Date(metadata?.snippet?.publishedAt).toLocaleDateString()}
            </span>
            <div>
              {new Intl.NumberFormat("en-US").format(
                metadata?.statistics?.viewCount
              )}{" "}
              views
            </div>
          </div>
        )}
        {/* {ctaButton && (
          <span className={`${styles.buttonWrapper}`}>{button}</span>
        )} */}
      </div>
    </div>
  );
}

export default BlazeVideoSlide;
