export function getYouTubeId(videoUrl) {
  if (!videoUrl) return null;

  const youTubeRegex =
    /(?:youTube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
  let youTubeId;

  const videoUrlString =
    typeof videoUrl !== "string" ? videoUrl.toString() : videoUrl;

  if (videoUrlString.includes("?v=")) {
    youTubeId = videoUrl
      .match(youTubeRegex)[0]
      .replace("youtube.com/watch?v=", "")
      .trim();
  } else if (videoUrlString.includes("youtu.be")) {
    youTubeId = videoUrlString
      .match(youTubeRegex)[0]
      .replace("youtu.be/", "")
      .trim();
  } else {
    youTubeId = videoUrl;
  }

  return youTubeId;
}

export async function fetchYouTubeData(video) {
  try {
    const youTubeId = getYouTubeId(video.url);
    const youTubeResponse = await fetch(
      `https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&id=${youTubeId}&key=${process.env.GOOGLE_API_KEY}`
    );
    const data = await youTubeResponse.json();

    if (!!data?.error) {
      console.error(data?.error);
      return null;
    }

    return data?.items?.[0];
  } catch (error) {
    console.error(error);
  }
}
