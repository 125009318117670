import Link from "next/link";
import LoadingAnimation from "../../loaders/LoadingAnimation/LoadingAnimation";
import styles from "./Button.module.scss";

const Button = ({
  htmlType = "button",
  link,
  fill = "dark",
  children,
  onClick,
  classes = [],
  ariaLabel = "Click button to interact",
  customStyles,
  center = false,
  disabled,
  target = "_self",
  linkAlt,
  loading = false,
}) => {
  let classNames = `${styles.button} ${styles[fill]} ${classes.join(" ")}`;

  if (loading) {
    return (
      <button
        disabled={true}
        type={htmlType}
        className={classNames}
        aria-label={ariaLabel}
        style={customStyles}
      >
        <div className={`${styles.loading}`}>
          <LoadingAnimation animation="donutFill" size="small" />
        </div>
      </button>
    );
  }

  if (link) {
    return (
      <Link
        href={link}
        className={`${classNames} ${styles.link} ${
          disabled ? styles.disabled : ""
        }`}
        // alt={linkAlt ?? `Link to ${link}`}
        style={customStyles}
        target={target}
      >
        {children}
      </Link>
    );
  }
  return (
    <button
      type={htmlType}
      className={`${classNames} ${
        disabled ? styles.disabled : ""
      } ${classes.join(" ")}`}
      disabled={disabled}
      onClick={onClick}
      aria-label={ariaLabel}
      style={customStyles}
    >
      {children}
    </button>
  );
};

export default Button;
