import styles from "./LoadingAnimation.module.scss";

const LoadingAnimation = ({
  animation = "donutFill",
  size = "medium",
  time = "10s",
}) => {
  if (["donutFill", "globe", "line"].includes(animation)) {
    return (
      <div className={`${styles.container} wn-loading-animation-container`}>
        {(animation === "donutFill" || !animation) && (
          <span
            className={`${styles.donutFill} ${
              styles[`${size}Size`]
            } wn-loading-animation`}
          ></span>
        )}

        {animation === "globe" && (
          <span className={`${styles.globe} ${styles[`${size}Size`]}`}></span>
        )}
        {animation === "line" && (
          <span className={`${styles.line} ${styles[`${size}Size`]}`}></span>
        )}
      </div>
    );
  }

  if (["progressBar", "progressBarStepped"].includes(animation)) {
    return (
      <div className={`${styles.fullLoadingContainer}`}>
        {["progressBar", "progressBarStepped"].includes(animation) && (
          <span
            className={`${styles.progressBar} ${
              animation === "progressBarStepped" ? styles.stepped : ""
            }`}
            style={{ animationDuration: time }}
          ></span>
        )}
      </div>
    );
  }
};

export default LoadingAnimation;
