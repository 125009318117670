// import Image from "next/image";
import dynamic from "next/dynamic";
const CldImage = dynamic(() =>
  import("next-cloudinary").then((mod) => mod.CldImage)
);
// const Image = dynamic(() => import("next/image"));
// import { CldImage } from "next-cloudinary";
// import { useFavorites } from "lib/hooks/useFavorites";
const FavoritesButton = dynamic(() =>
  import("components/ui/buttons/FavoriteButton/FavoriteButton")
);
import Link from "next/link";

import Button from "components/ui/buttons/Button/Button";
import { convertAspectRatio, insertTagsInCloudinaryUrl } from "utils/image";
import styles from "./BlazeSlide.module.scss";

export default function BlazeSlide({
  id,
  type,
  imageURL,
  mobileImageURL,
  alt = "Slider image",
  title,
  text,
  link,
  imageWidth = 1366,
  imageHeight = 600,
  ctaButton = false,
  loading = "lazy",
  mobileText = "",
  index,
  sizes,
  slideRef,
  aspectRatio,
  favoriteType,
}) {
  const button = (
    <Button
      link={`${link}`}
      fill="bright"
      linkAlt={`Link to ${title} page`}
      customStyles={{
        maxWidth: "300px",
        // fontSize: "var(--font-size-md-sm)",
        fontWeight: "400",
        padding: "var(--spacing-xs)",
      }}
    >
      Read more
    </Button>
  );

  let image;

  if (aspectRatio) {
    const aspectRatioArray = aspectRatio.split("/");
    const numericAspectRatio = aspectRatioArray[0] / aspectRatioArray[1];
    const newDimensions = convertAspectRatio(
      imageWidth,
      imageHeight,
      numericAspectRatio
    );

    image = (
      <CldImage
        width={newDimensions.width ?? 1366}
        height={newDimensions.height ?? 660}
        crop="fill"
        src={imageURL}
        loading={loading}
        alt={alt ?? "Slider image"}
        style={{ width: "100%" }}
        sizes={
          sizes ??
          `(min-width: 320px) 100vw,
          (min-width: 1100px) 75vw,
          100vw`
        }
        priority={index === 0}
      />
    );
  } else {
    image = (
      <CldImage
        width={imageWidth ?? 1366}
        height={imageHeight ?? 660}
        src={imageURL}
        loading={loading}
        alt={alt ?? "Slider image"}
        sizes={
          sizes ??
          `(min-width: 320px) 100vw,
          (min-width: 1100px) 75vw,
          100vw`
        }
        priority={index === 0}
      />
    );
  }

  return (
    <div
      className={`${styles.container} }`}
      key={imageURL}
      style={{ aspectRatio: `${aspectRatio ?? "unset"}` }}
    >
      {["experience", "experienceMinimal"].includes(type) && (
        // <div className={`${styles.favoriteButton}`}>
        <FavoritesButton objectId={id} favoriteType={favoriteType} />
        // </div>
      )}
      <div
        className={`${styles.imageContainer} `}
        style={{ aspectRatio: `${aspectRatio ?? "unset"}` }}
      >
        {image}
      </div>

      {title && text && (
        <div className={`${styles.textContainer}`}>
          <div className={`${styles.titleText}`}>
            <div className={`${styles.modal}`}>
              {link && (
                <Link href={link}>
                  {title && <div className={`${styles.title}`}>{title}</div>}
                  {text && <div className={`${styles.text}`}>{text}</div>}
                </Link>
              )}
              {!link && (
                <>
                  {title && <div className={`${styles.title}`}>{title}</div>}
                  {text && <div className={`${styles.text}`}>{text}</div>}
                </>
              )}
            </div>
            {link && ctaButton && (
              <span className={`${styles.buttonWrapper}`}>{button}</span>
            )}
          </div>
        </div>
      )}

      {(text || mobileText || ctaButton) && (
        <div className={`${styles.mobileSubText}`}>
          {text && <div className={`${styles.text}`}>{mobileText}</div>}
          {ctaButton && (
            <span className={`${styles.buttonWrapper}`}>{button}</span>
          )}
        </div>
      )}
    </div>
  );
}
